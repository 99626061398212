/* global setTimeout, clearTimeout */
const {render, Fragment} = require("inferno"),
    {createClass: createComponent} = require("inferno-create-class"),
    Stage = require("@naikus/stage"),

    Touchable = require("@components/touchable"),
    Activables = require("@lib/activables"),
    {Notifications} = require("@components/notification"),
    LoadingIndicator = require("@components/loading-indicator"),

    Config = require("@app/config"),
    createRouter = require("@lib/router"),


    StageComponent = createComponent({
      componentDidMount() {
        this.setupStage();
      },
      render() {
        return (
          <div ref={element => this.viewport = element} className="stage-viewport"></div>
        );
      },
      // Stage component manages its own views and rendering lifecycle
      shouldComponentUpdate() {
        return false;
      },
      componentWillUnmount() {
        this.deregisterListeners();
      },
      getViewContext() {
        return this.stageInstance.getViewContext();
      },
      getViewConfig(viewId) {
        return this.stageInstance.getViewConfig(viewId);
      },
      getViewController(viewId) {
        return this.stageInstance.getViewController(viewId);
      },
      setupStage() {
        const {viewport, props: {
          startView, viewConfig,
          transition,
          contextFactory
        }} = this;

        let stageInstance = this.stageInstance = Stage({
          viewport: viewport,
          transition: transition,
          transitionDelay: 10,
          contextFactory
        });

        // Register view load listeners
        this.registerListeners();

        // Register all the routes
        viewConfig.forEach(vc => {
          // console.log(vc);
          if(!vc) {return;}
          const {id, src, config} = vc;
          Stage.view(id, src, config);
        });

        if(startView) {
          stageInstance.getViewContext().pushView(startView, {});
        }
      },
      registerListeners() {
        const {viewport, props: {
          onViewLoadStart,
          onViewLoadEnd,
          onBeforeViewTransitionIn,
          onBeforeViewTransitionOut
        }} = this;

        if(typeof onViewLoadStart === "function") {
          viewport.addEventListener("viewloadstart", onViewLoadStart);
        }
        if(typeof onViewLoadEnd === "function") {
          viewport.addEventListener("viewloadend", onViewLoadEnd);
        }
        if(typeof onBeforeViewTransitionIn === "function") {
          viewport.addEventListener("beforeviewtransitionin", onBeforeViewTransitionIn);
        }
        if(typeof onBeforeViewTransitionOut === "function") {
          viewport.addEventListener("beforeviewtransitionout", onBeforeViewTransitionOut);
        }
      },
      deregisterListeners() {
        const {stageInstance, viewport, props: {
          onViewLoadEnd,
          onViewLoadStart,
          onBeforeViewTransitionIn,
          onBeforeViewTransitionOut
        }} = this;
        if(typeof onViewLoadEnd === "function") {
          viewport.removeEventListener("viewloadend", onViewLoadEnd);
        }
        if(typeof onViewLoadStart === "function") {
          viewport.removeEventListener("viewloadstart", onViewLoadStart);
        }
        if(typeof onBeforeViewTransitionIn === "function") {
          viewport.removeEventListener("viewloadend", onBeforeViewTransitionIn);
        }
        if(typeof onBeforeViewTransitionOut === "function") {
          viewport.removeEventListener("viewloadstart", onBeforeViewTransitionOut);
        }
      }
    }),

    App = createComponent({
      displayName: "App",
      defaultTransition: "fade",
      contextFactory(stage, stageOpts) {
        const self = this;
        return {
          router: self.router,
          // "Override" these functions if you'd like to do anything additional things
          // before pushing views. e.g. check user permissions
          /*
          pushView(viewId, options) {
            // @todo Check if view is allowed for the current user
            // console.log("[App]: Pushing view", viewId, options);
            return stage.pushView(viewId, options);
          },
          ...
          */
          getConfig() {
            return Config
          },
          showNotification(message) {
            self.notifications.enqueue(message);
          }
        };
      },
      // Stage event listeners
      onBeforeViewTransitionIn(e) {
        const viewId = e.viewId,
            controller = this.stageComponent.getViewController(viewId),
            viewConfig = this.stageComponent.getViewConfig(viewId);
        this.setState({
          viewId,
        });
      },
      onBeforeViewTransitionOut(e) {
        const {viewId} = e;
      },
      onViewLoadStart(e) {
        this.setState({loading: true});
      },
      onViewLoadEnd(e) {
        const {viewId, error} = e;
        this.setState({loading: false});
      },

      // Lifecycle methods
      getInitialState() {
        this.viewConfig = Config.routes.map(route => route.view);
        const routeController = context => {
          return context.route;
        };
        Config.routes.forEach(route => {
          if(!route.controller) {
            route.controller = routeController;
          }
        });

        this.router = createRouter(Config.routes);
        this.router.on("route", (event, data) => {
          const {view, action, params, state} = data, {stageComponent} = this,
            viewContext = stageComponent.getViewContext(),
            currentView = viewContext.currentView(),
            viewOptions = Object.assign({}, state, {params: params});

          if((currentView === view.id) || action !== "POP") {
            stageComponent.getViewContext().pushView(view.id, viewOptions);
          }else {
            stageComponent.getViewContext().popView(viewOptions);  
          }
        });
        this.router.start();
        return {
          loading: false        
        };
      },
      componentDidMount() {
        const {startRoute = "/"} = this.props;
        this.router.route(startRoute);
      },
      render() {
        const {defaultTransition} = this,
            {startView = "settings", transition=defaultTransition} = this.props,
            {loading} = this.state;
        return (
          <Fragment>
            <StageComponent ref={comp => this.stageComponent = comp}
              viewConfig={this.viewConfig}
              transition={transition}
              contextFactory={this.contextFactory.bind(this)}
              onViewLoadStart={this.onViewLoadStart.bind(this)}
              onViewLoadEnd={this.onViewLoadEnd.bind(this)}
              onBeforeViewTransitionIn={this.onBeforeViewTransitionIn.bind(this)} />
            {/* onBeforeViewTransitionOut={this.onBeforeViewTransitionOut.bind(this)} /> */}
            <Notifications ref={comp => this.notifications = comp} />
            {loading ? <LoadingIndicator /> : null}
          </Fragment>
        );
      }
    });

/**
 * Run the app
 */
function initialize() {
  const activables = Activables(document);
  // Start the activables
  activables.start();
  window.addEventListener("unload", event => {
    activables.stop();
  });
  // const startView = "main";
  // set document title
  document.title = Config.appName;
  // set favicon
  const favElem = document.getElementById("favicon");
  favElem && favElem.setAttribute("href", `branding/${Config.branding}/images/favicon.svg`);

  const browserRoute = window.location.hash.substring(1),
      startRoute = browserRoute || "/";

  render(
    <App startRoute={startRoute} />,
    document.getElementById("shell")
  );
}

function registerServiceWorker() {
  if("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/sw.js").then(
      registration => {
        console.log("Service worker registered", registration);
      },
      error => {
        console.error("Service worker registration failed", error)
      }
    );
  }
}

module.exports = {
  run() {
    initialize();
    // console.log(Config);
    if(Config.pwa) {
      registerServiceWorker();
    }
  }
};
