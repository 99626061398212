/* global require process */
// All new customer configs MUST be imported first
require("./branding/default/config");

const brandConfig = require(`./branding/${process.env.BRANDING || "default"}/config`);


module.exports = Object.assign({}, {
  appName: "The Fat Labrador Cafe",
  appNamespace: "fatlabe-web",
  appVersion: process.env.APP_VERSION,

  apiServerUrl: process.env.API_SERVER || "",
  apiServerPort: 7001,
  apiBasePath: "/api",
  branding: process.env.BRANDING,
  pwa: process.env.PWA,

  routes: [
    {
      path: "/",
      controller() {
        return {
          redirect: "/main"
        };
      }
    },
    {
      path: "/main",
      view: {
        id: "main",
        src: "views/main/index.js",
        config: {}
      }
    },
    {
      path: "/menu",
      view: {
        id: "menu",
        src: "views/menu/index.js",
        config: {}
      }
    },
    {
      path: "/contact",
      view: {
        id: "contact",
        src: "views/contact/index.js",
        config: {}
      }
    }
  ]
}, brandConfig);
